import { url } from "@/event/url/url"
import { arrayUrlTest } from "./constants"

export const urlWebSocket = (() => {
    if (arrayUrlTest.includes(url.host)) {
        return "https://api.eronor.eu:4000"
    } else {
        return "https://api.eronor.info:4000"
    }
})()

