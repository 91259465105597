// useStoreInfo.ts

import { onUnmounted, ref, watch, type Ref } from 'vue'
import stores, { type HoursType, type StoresDataType } from '@/storage/stores'
import setting, { type OpeningHoursType } from '@/storage/setting'
import { storeIsOpen } from '@/event/closeBus'

const { state } = stores
const { storeData } = state

export const title = ref()
export const classTitle = ref()
export const textTitle = ref('')
let intervalId: number | undefined

export function useStoreInfo() {
  const text = ref('')
  const storeSetting = ref()
  const arrayPlanning = ref<HoursType[]>([])
  const stopInterval = ref(false)

  const testDay = ref<number | null>(null)
  const testHour = ref<number | null>(null)
  const testMinute = ref<number | null>(null)
  const testTime = ref<number | null>(null)

  const updateStore = (storeData: StoresDataType) => {
    const classOpen = 'open-store'
    const classClose = 'close-store'
    const classDefault = 'default-siege'

    const textForOpen = `Le système de commande est actif, vous pouvez passer votre commande et la récupérer à partir de `
    const textOpenClose =
      'Le restaurant est fermé pour la commande en ligne. Veuillez réessayer plus tard.'
    const textTodayClose = "Le restaurant a fermé pour aujourd'hui. Veuillez revenir demain"
    const textClosedForTonight = 'Le restaurant est fermé pour ce soir. Veuillez revenir demain'
    const textNotsortedPlanning = "Le restaurant n'a pas fourni d'horaire d'ouverture"

    if (storeData.name) {
      const storeConfigWebOrder = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_web_order'
      )
      const configWebOrder = storeConfigWebOrder ?? storeData.config.web_order
      const configOpeningHours = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_opening_hours'
      )

      if (configOpeningHours) {
        arrayPlanning.value = (configOpeningHours.value as OpeningHoursType).opening_hours
      } else {
        arrayPlanning.value = []
      }

      if (Number(configWebOrder.value) !== 0) {
        const today = new Date()
        const todayDay = testDay.value !== null ? testDay.value : today.getUTCDay() || 7
        const todayHours = testHour.value !== null ? testHour.value : today.getHours()
        const todayMinutes = testMinute.value !== null ? testMinute.value : today.getMinutes()
        const todayTime = testTime.value !== null ? testTime.value : today.getTime()

        const isOpen = ref(false)
        let nextOpeningTime = ''
        let closedForTonight = false

        const sortedPlanning = [...arrayPlanning.value].sort((a, b) => {
          const [aHour] = a.open.split(':').map(Number)
          const [bHour] = b.open.split(':').map(Number)
          return aHour - bHour
        })

        let foundCurrentPeriod = false
        let foundNextPeriod = false

        const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
        const allDays = sortedPlanning.map((planning) => planning.days).flat()

        if (!allDays.includes(todayDay.toString()) && sortedPlanning.length > 0) {
          const nextDayOpening = sortedPlanning.find((planning) =>
            planning.days.includes(((todayDay + 1) % 7).toString())
          )

          const nextOpeningDayNumber = nextDayOpening
            ? parseInt(nextDayOpening.days[0], 10)
            : (todayDay + 1) % 7

          const nextOpeningDayName = daysOfWeek[nextOpeningDayNumber]
          nextOpeningTime = nextDayOpening ? nextDayOpening.open : ''

          updateUI(
            `Le restaurant est fermé aujourd'hui. Il ouvrira ${nextOpeningDayName} à ${nextOpeningTime}`,
            classClose,
            storeData.name
          )
          return
        } else {
          storeIsOpen.value = true
        }

        for (const planning of sortedPlanning) {
          if (planning.days.includes(todayDay.toString())) {
            const [openHour, openMinute] = planning.open.split(':').map(Number)
            const [closeHour, closeMinute] = planning.close.split(':').map(Number)
            const timeClose = new Date()
            timeClose.setHours(closeHour, closeMinute)
            // const fifteenMinutesBeforClose = timeClose.getTime() - 15 * 60 * 1000

            const isAfterOpen =
              todayHours > openHour || (todayHours === openHour && todayMinutes >= openMinute)
            const isBeforeClose =
              todayHours < closeHour || (todayHours === closeHour && todayMinutes < closeMinute)

            if (isAfterOpen && isBeforeClose) {
              isOpen.value = true
              foundCurrentPeriod = true
              const fifteenMinutesBeforClose = timeClose.getTime() - 15 * 60 * 1000
              if (todayTime >= fifteenMinutesBeforClose && isOpen.value) {
                intervalId = window.setInterval(
                  () => {
                    testTime.value = new Date().getTime() + 6 * 120 * 60 * 1000
                    updateStore(storeData)
                  },
                  5 * 60 * 1000
                )
              }
              break
            }

            if (todayHours < openHour || (todayHours === openHour && todayMinutes < openMinute)) {
              nextOpeningTime = planning.open
              foundNextPeriod = true
              break
            }

            if (todayHours >= closeHour) {
              foundCurrentPeriod = false
              clearInterval(intervalId)
            }
          }
        }

        if (!foundCurrentPeriod && !foundNextPeriod) {
          const lastPeriod = sortedPlanning
            .filter((planning) => planning.days.includes(todayDay.toString()))
            .pop()
          if (lastPeriod) {
            const [lastCloseHour, lastCloseMinute] = lastPeriod.close.split(':').map(Number)
            if (
              todayHours >= lastCloseHour &&
              (todayMinutes >= lastCloseMinute || todayHours > lastCloseHour)
            ) {
              closedForTonight = true
            }
          }

          const nextDayOpening = sortedPlanning.find((planning) =>
            planning.days.includes(((todayDay + 1) % 7).toString())
          )
          nextOpeningTime = nextDayOpening ? nextDayOpening.open : ''
        }

        if (isOpen.value) {
          updateUI('', classOpen, storeData.name)
        } else if (closedForTonight) {
          updateUI(textClosedForTonight, classClose, storeData.name)
        } else if (nextOpeningTime) {
          updateUI(`${textForOpen} ${nextOpeningTime}`, classDefault, storeData.name)
        } else if (sortedPlanning.length === 0) {
          storeIsOpen.value = false
          updateUI(textNotsortedPlanning, classClose)
        } else {
          updateUI(textTodayClose, classClose, storeData.name)
        }
      } else {
        storeIsOpen.value = false
        updateUI(textOpenClose, classClose, storeData.name)
      }
    }
  }

  const updateUI = (message: string, className: string, storeName?: string) => {
    textTitle.value = message
    title.value = storeName ? (message ? `${storeName}: ${message}` : storeName) : message
    classTitle.value = className
  }

  // Simuler le changement d'heure de fermeture après 60 secondes
  const simulateCloseTimeChange = () => {
    testHour.value = 14
    testMinute.value = 30
    stopInterval.value = true
  }

  return {
    updateStore,
    storeData,
    testDay,
    testHour,
    testMinute
  }
}
