export const getOptionNameForBasket = (option: { language: any[] }, languageId: number) => {
    const lang = option.language.find((lang) => lang.language_id === languageId)

    if (lang) {
        if (lang.label !== null && lang.label !== undefined && lang.label.length !== 0) {
            return lang.label
        }
        return lang.name
    }

    return 'Unknown'
}