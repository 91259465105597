<script setup lang="ts">
// OrderView.vue
import MyInformationOrder from '@/components/account/order/MyInformationOrder.vue'
import OptionOrder from '@/components/account/order/OptionOrder.vue'
import SelectHoursOrder from '@/components/account/order/SelectHoursOrder.vue'
import ValidateOrder from '@/components/account/order/ValidateOrder.vue'
import Alert from '@/components/alert/Alert.vue'
import { messageAddAddress } from '@/event/account/dashboard/messageAddAddress'
import { cartBus, showCartBus } from '@/event/cartBus'
import {
    orderData,
    updateFieldOrderData,
    type OrderDataProductsOptionType,
    type OrderDataProductsType
} from '@/event/order/data/orderData'
import { messageSend } from '@/event/order/messageSend'
import { showDeliveryZones } from '@/event/order/showDeliveryZones'
import { configCheckoutRestaurant } from '@/storage/setting/configCheckoutRestaurant'
import customer from '@/storage/customer'
import { paymentStripeproStatus } from '@/storage/paymentStripeproStatus'
import stores from '@/storage/stores'
import { submitOrder } from '@/utils/order/submitOrder'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { toggleAddAddress } from '@/utils/toggle/dashboard/toggleAddAddress'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { shippingCost } from '@/event/order/data/shippingCost'
import ConditionUseModal from '@/components/main/information/ConditionUseModal.vue'
import { showConditionUseModalBus } from '@/event/main/information/showConditionUseModalBus'
import { toggleShowConditionUseModal } from '@/utils/toggle/information/toggleShowConditionUseModal'
import { toggleShowPrivacyPolicyModal } from '@/utils/toggle/information/toggleShowPrivacyPolicyModal'
import PrivacyPolicyModal from '@/components/main/information/PrivacyPolicyModal.vue'
import { showPrivacyPolicyModalBus } from '@/event/main/information/showPrivacyPolicyModalBus'
import { formatStoreName } from '@/utils/store/formatStoreName'
import { useRouter } from 'vue-router'
import address from '@/storage/address'

const PaymentOrder = defineAsyncComponent(
    () => import('@/components/account/order/PaymentOrder.vue')
)
const AlertDeliveryZones = defineAsyncComponent(
    () => import('@/components/account/order/AlertDeliveryZones.vue')
)
const Ariane = defineAsyncComponent(() => import('@/components/ariane/Ariane.vue'))
const ButtonOrderSend = defineAsyncComponent(
    () => import('@/components/button/order/ButtonOrderSend.vue')
)
const ButtonPaymentOrder = defineAsyncComponent(
    () => import('@/components/button/order/ButtonPaymentOrder.vue')
)
const Payment = defineAsyncComponent(() => import('@/components/button/order/Payment.vue'))

const selectTable = ref(false)
const tableName = ref('')
const currentStep = ref('step0')
const takeOrder = ref(false)
const deliveryOrder = ref(false)
const finalStep = ref(true)

const router = useRouter()

const userAgent = navigator.userAgent

let methodPayment: any

const store = stores.state.storeData.name
const key = store ? `${formatStoreName(store)}_table` : 'table'

const tableLocalStorage = localStorage.getItem(key)

if (tableLocalStorage) {
    const table = JSON.parse(tableLocalStorage)

    if (table.expiration > new Date().getTime()) {
        tableName.value = table.value
        selectTable.value = true
    } else if (table.expiration < Date.now()) {
        selectTable.value = false
        tableName.value = ''
        localStorage.removeItem('table')
    }
}

const handleContinue = (eventData: any) => {
    if (selectTable.value) {
        if (['step1'].includes(eventData.step)) {
            currentStep.value = 'step3'
            finalStep.value = true
        }

        if (['step4', 'step3'].includes(eventData.step)) {
            currentStep.value = eventData.step
            finalStep.value = true

            updateFieldOrderData({
                order_time: 'Today',
                shipping_company: null,
                shipping_address_1: null,
                shipping_address_2: null,
                shipping_city: null,
                shipping_postcode: null,
                shipping_country_id: null,
                shipping_city_id: null,
                shipping_zone_id: null,
                shipping_method: 'Sur place',
                shipping_price: '',
                shipping_code: '5',
                total: Number(totalPrice.value).toFixed(2),
                table_no: tableName.value
            })
        }

        if (eventData.step == 'step5') {
            finalStep.value = false
            updateFieldOrderData({
                user_agent: userAgent
            })

            if (methodPayment === 'Card') {
                takeOrder.value = false
            } else {
                deliveryOrder.value = false
            }
        }

        if (eventData.method_payment === 'Card') {
            deliveryOrder.value = true
            methodPayment = 'Card'
            takeOrder.value = false
            updateFieldOrderData({ order_status: 0 })
        } else if (eventData.method_payment === 'Counter') {
            deliveryOrder.value = false
            takeOrder.value = true
            methodPayment = 'Counter'
            updateFieldOrderData({ order_status: 1 })
        }
    } else {
        if (['step0', 'step1', 'step2', 'step3', 'step4'].includes(eventData.step)) {
            currentStep.value = eventData.step
            finalStep.value = true
        }

        if (eventData.step == 'step5') {
            finalStep.value = false
            updateFieldOrderData({ user_agent: userAgent })
            if (methodPayment === 'Card') {
                takeOrder.value = false
            } else {
                deliveryOrder.value = false
            }
        }

        if (eventData.method_payment === 'Card') {
            deliveryOrder.value = true
            methodPayment = 'Card'
            takeOrder.value = false
            updateFieldOrderData({ order_status: 0 })
        } else if (eventData.method_payment === 'Counter') {
            deliveryOrder.value = false
            takeOrder.value = true
            methodPayment = 'Counter'
            updateFieldOrderData({ order_status: 1 })
        }
    }
}

const canPlaceOrder = computed(() => {
    if (stores.state.storeData.status === 1) {
        return true
    }

    if (
        Number(stores.state.storeData.status) === 2 &&
        Number(customer.state.customerData.status === 2)
    ) {
        return true
    }
    return false
})

const totalPriceOrderView = computed(() => {
    const total = convertPriceToEuro(totalPrice.value) + shippingCost.value
    return Number(total).toFixed(2)
})

// onMounted(() => {
// })

watch(
    () => cartBus.value,
    () => {
        if (cartBus.value.length === 0) {
            const dataMessage = {
                message: "Votre panier est vide vous allez être redirigé vers la page d'accueil",
                class: 'error',
                errors: [],
                address: 0
            }
            messageAddAddress.value = dataMessage

            setTimeout(() => {
                if (showCartBus.value === true) {
                    showCartBus.value = false
                }
                toggleAddAddress()
                router.push('/')
            }, 3000)
        }
    },
    { deep: true }
)
</script>

<template>
    <section class="section-basket-order">
        <Alert :function="toggleAddAddress" :message="messageAddAddress" />
        <AlertDeliveryZones v-if="showDeliveryZones" />
        <ConditionUseModal v-if="showConditionUseModalBus" />
        <PrivacyPolicyModal v-if="showPrivacyPolicyModalBus" />
        <Ariane />

        <section class="section-content-basket-order">
            <div class="div-content-basket-order">
                <h3 class="h3-validate-order">Validation de commande</h3>
                <p class="text-rising">
                    Montant de la commande :
                    <span class="text-amber-eronor">{{ totalPriceOrderView }}€</span>
                </p>
            </div>

            <p
                v-if="paymentStripeproStatus === '0' && configCheckoutRestaurant === '0'"
                class="text-center bg-red-eronor rounded-md py-1"
            >
                Les moyens de paiement ne sont pas disponibles !
            </p>
            <p v-if="!canPlaceOrder" class="text-center bg-red-eronor rounded-md py-1">
                Le site est une démonstration vous ne pourrait pas passer de commande
            </p>

            <MyInformationOrder :isOpenInitial="currentStep" @continue="handleContinue" />
            <OptionOrder :isOpenInitial="currentStep" @continue="handleContinue" />
            <SelectHoursOrder :isOpenInitial="currentStep" @continue="handleContinue" />
            <PaymentOrder :isOpenInitial="currentStep" @continue="handleContinue" />
            <ValidateOrder :isOpenInitial="currentStep" @continue="handleContinue" />
        </section>

        <p v-if="messageSend.message" :class="messageSend.class">{{ messageSend.message }}</p>
        <section class="section-button-payment" v-if="canPlaceOrder">
            <!-- <ButtonPaymentOrder v-if="!finalStep && deliveryOrder" :finishStep="finalStep && cartBus.length > 0"
        @click="submitOrder" /> -->
            <ButtonOrderSend
                v-if="!finalStep && takeOrder && cartBus.length > 0"
                @click="submitOrder"
            />
            <Payment
                v-if="!finalStep && deliveryOrder && cartBus.length > 0"
                class="section-payment-stripe-element"
            />
        </section>

        <div class="max-w-[60rem] text-center pt-5">
            <p class="text-sm">
                En validant cette commande, vous confirmez avoir pris connaissance et accepté nos
                <button
                    class="text-lime-eronor hover:underline"
                    @click="toggleShowConditionUseModal"
                >
                    Générales d'Utilisation
                </button>
                ainsi que notre
                <button
                    class="text-lime-eronor hover:underline"
                    @click="toggleShowPrivacyPolicyModal"
                >
                    Politique de Confidentialité</button
                >. Nous vous encourageons à les lire attentivement avant de finaliser votre achat
            </p>
        </div>
    </section>
</template>

<style scoped>
.success {
    @apply flex flex-col bg-lime-eronor px-3 pt-1 pb-1 mt-2 w-full text-center rounded-md;
}

.error {
    @apply flex flex-col bg-red-eronor px-3 pt-1 pb-1 mt-2 w-full text-center rounded-md;
}

.section-basket-order {
    @apply px-3 w-full flex flex-col items-center pb-6 relative;
}

.div-content-basket-order {
    @apply border-b border-b-slate-eronor flex flex-col justify-between items-center p-1 pb-3;
}

.h3-validate-order {
    @apply font-semibold text-lg;
}

.text-rising {
    @apply pt-1;
}

.section-button-payment {
    @apply pt-5 w-full flex items-center justify-center;
}

.section-content-basket-order {
    @apply pt-2 w-full flex flex-col gap-4;
}

.section-payment-stripe-element {
    @apply flex flex-col min-w-full items-center justify-center gap-2;
}

@media screen and (min-width: 800px) {
    .section-payment-stripe-element {
        @apply min-w-[50rem];
    }
}

@media screen and (min-width: 700px) {
    /* .section-content-dashboard-history {
    @apply flex-row justify-between gap-4;
  } */
    .div-content-basket-order {
        @apply flex-row px-4;
    }
}

@media screen and (min-width: 900px) {
    .section-basket-order {
        @apply w-[55rem];
    }
}

@media screen and (min-width: 1200px) {
    .section-basket-order {
        @apply w-[70rem];
    }
}
</style>
