<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue'
import Section from '@/components/main/Section.vue'
import SectionContact from '@/components/main/SectionContact.vue'
import SectionHours from '@/components/main/SectionHours.vue'
import stores from '@/storage/stores'

const nameStore = ref<string>('Eronor.com')

watch(
    () => stores.state.storeData,
    () => {
        nameStore.value = stores.state.storeData.name || 'Eronor.com'
    },
    { immediate: true }
)
</script>

<template>
    <footer class="" :class="{}">
        <div class="middle-footer">
            <div class="div-section">
                <SectionContact :nameStore="nameStore" title="Contact" />
                <Section title="Informations du site" />
                <SectionHours title="Horaires / Livraison" />
            </div>
        </div>

        <section class="px-6 bg-black">
            <div class="w-full text-center border-t-[0.1px] border-gray-600 py-2">
                <p>© 2025 Eronor. Tous droits réservés.</p>
            </div>
        </section>
    </footer>
</template>

<style scoped>
footer {
    @apply w-full pt-4;
}

.middle-footer {
    @apply flex flex-grow bg-black w-full bottom-0 p-2 min-h-full;
    @apply smoke-white:text-white;
}

.div-section {
    @apply flex flex-col flex-grow;
}

@media screen and (min-width: 800px) {
    .div-section {
        @apply grid grid-cols-3 justify-items-center mx-auto;
    }
}

@media screen and (min-width: 1000px) {
    .div-section {
        @apply max-w-[60rem];
    }
}

@media screen and (min-width: 1280px) {
    .div-section {
        @apply max-w-[65rem];
    }
}

@media screen and (min-width: 1500px) {
    .div-section {
        @apply max-w-[70rem];
    }
}

@media screen and (min-width: 2000px) {
    .div-section {
        @apply max-w-[80rem];
    }
}
</style>
